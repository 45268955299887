<template>
  <div class="content" v-loading="contentLoading">
    <div class="top">
      <el-row>
          <el-col :span="18">
            <div class="search" v-if="!isShowEditor">
              <el-input
          v-model="input"
          clearable
          class="search-input"
          placeholder="Search"
          @keyup.enter="handleSearch"
        />
            </div>
          </el-col>
          <el-col :span="2.5" v-if="!isShowEditor">
            <el-button class="search-icon" type="primary" :icon="Search"  @click="handleSearch">搜索</el-button>
          </el-col>
          <el-col :span="2.5" v-if="!isShowEditor">
            <el-button class="reset-icon"  :icon="Refresh" type="info"  @click="handleReset">重置</el-button>
          </el-col>
    </el-row>
      <!-- <el-button @click="add" class="add-icon" type="primary">新增</el-button> -->
    </div>
    <div class="content-collapse" v-if="!isShowEditor && articleTitleList">
      <div v-if="articleTitleList.data.length == 0" class="fankui">
        <p>没有找到相关内容，请换一种说法试试</p>
        <p>
          <span>
            我要
            <a @click="() => { dialogVisible = true }">反馈问题</a>
          </span>
        </p>
      </div>
      <div class="collapse-div" v-for="(item, index) in articleTitleList.data" :key="index">
        <div class="demo-collapse">
          <el-collapse v-model="activeName" accordion @change="handleChange">
            <el-collapse-item :name="item.id">
              <template #title>
                <div style="width: 92%">{{ item.title }}</div>
                <el-button
                  v-if="userName == 'admin' || userName == 'device admin'"
                  class="delete-btn"
                  size="small"
                  type="danger"
                  @click.stop="deleteA(item.id)"
                >删除</el-button>
                <el-button
                  v-if="userName == 'admin' || userName == 'device admin'"
                  class="modify-btn"
                  size="small"
                  type="primary"
                  @click.stop="modify(item.menuId, item.menuFatherId, item.id, item.menuName)"
                >修改</el-button>
              </template>
              <div v-loading="loading" v-html="content"></div>
              <div class="feed">
                <p>以上是锐曼技术支持专家提供的问题解答,如果没解决您的问题请在下面提交反馈</p>
                <el-button class="feed-btn" size="medium" type="primary" @click="feed(item)">我要反馈</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
  <Editor v-if="isShowEditor" />
  <el-dialog v-model="dialogVisible" title="提交反馈" width="30%">
    <el-input
      v-model="textarea"
      :autosize="{ minRows: 4, maxRows: 6 }"
      type="textarea"
      placeholder="Please input"
    ></el-input>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="subFeed">提交</el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="deleteDialogVisible" title="警告" width="30%">
    <span>确定要删除吗</span>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取消</el-button>
        <el-button type="danger" @click="subDelete">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  reactive,
  toRefs,
} from "vue";
import { Search } from "@element-plus/icons";
import {Refresh} from "@element-plus/icons";
import { useStore } from "vuex";
import { getArticleContent, getArticle, submitFeed,getHotPro,deleteArticle } from "../../service/request";
import Editor from "@/components/editor.vue";
import { ElMessage } from "element-plus";
export default defineComponent({
  name: "Content",
  components: { Editor },
  setup() {
    const store = useStore();
    const state = reactive({
      content: null,
      loading: false,
    });
    const input = ref("");
    const dialogVisible = ref(false)
    const deleteDialogVisible = ref(false)
    const textarea = ref('')
    const activeName = ref("");
    const feedStateId = ref("")
    const deleteId = ref("")
    const userName = ref('')
    const handleChange = (val) => {
      if (val) {
        state.loading = true;
        let parmas = {
          id: val,
        };
        getArticleContent(parmas).then((res) => {
          state.content = res.data.data.content;
          state.loading = false;
        });
      }
    };
    const add = () => {
      store.commit("setIsShowEditor", true);
      store.commit("setModifyId", 0);
      store.commit("setModifyContent", null);
      store.commit("setIsModifyStatus", false);
    };
    const modify = (id, menuFatherId, _id, menuName) => {
      store.commit("setId", id);
      store.commit("setModifyId", _id);
      store.commit("setMenuFatherId", menuFatherId);
      store.commit("setMenuName", menuName)
      let parmas = {
        id: _id,
      };
      getArticleContent(parmas)
        .then((res) => {
          store.commit("setModifyContent", res.data.data);
          store.commit("setIsShowEditor", true);
          store.commit("setIsModifyStatus", true);
        })
        .catch((err) => {
          ElMessage({
            message: "获取内容失败",
            type: "error",
          });
        });
    };

    const handleSearch = () => {
        let params = {};
        params.keyword = input.value;
        store.commit("setContentLoading", true);
        store.commit("setDefaultIndexMenu", null);
        getArticle(params).then((res) => {
          store.commit("setArticleTitleList", res.data.data);
          store.commit("setContentLoading", false);
        });  
    };

    const handleReset = () => {
      input.value = "";
      store.commit("setDefaultIndexMenu", "hot");
      getHotProblem();
    };

    const getHotProblem = () => {
      let parmas = {
        sort: "click_volume",
      };  
      getHotPro(parmas).then((res) => {
        store.commit("setArticleTitleList", res.data.data);
        store.commit("setContentLoading", false);
      });
    };

    const deleteA = (id) =>{
      deleteId.value = id
      deleteDialogVisible.value = true
    }

    const subDelete = () =>{
      console.log("删除========",deleteId.value)
      let params = {
        id:deleteId.value
      }
      deleteArticle(params).then(res => {
        ElMessage({
            message:"删除成功",
            type:"success"
        });
        store.commit("setDefaultIndexMenu", "hot");
        getHotProblem();
      }).catch(err =>{
        ElMessage({
          message: "删除失败",
          type: "error",
        });
      })
      deleteDialogVisible.value = false
      deleteId.value = ''
    }

    //反馈
    const feed = (item) => {
      feedStateId.value = item.id
      dialogVisible.value = true
    }
    //提交反馈
    const subFeed = () => {
      let params = {
        questions: textarea.value,
        articleId: feedStateId.value ? feedStateId.value : null
      }
      submitFeed(params).then(res => {
        ElMessage({
          message: "提交成功",
          type: "success",
        });
      }).catch(err => {
        ElMessage({
          message: "提交失败",
          type: "error",
        });
      })
      textarea.value = ''
      dialogVisible.value = false
    }
    onMounted(() => {
      userName.value = sessionStorage.getItem('user')
    });
    return {
      ...toRefs(state),
      feedStateId,
      input,
      Search,
      Refresh,
      activeName,
      handleChange,
      add,
      modify,
      handleSearch,
      handleReset,
      feed,
      deleteA,
      subFeed,
      subDelete,
      articleTitleList: computed(() => store.state.articleTitleList),
      isShowEditor: computed(() => store.state.isShowEditor),
      contentLoading: computed(() => store.state.contentLoading),
      dialogVisible,
      deleteDialogVisible,
      textarea,
      deleteId,
      userName
    };
  },
});
</script>

<style lang="less" scoped>
// .modify-btn{
//   // pointer-events: auto !important;
// }
:deep(.el-collapse-item__content) {
  background-color: #f5f5f5 !important;
  // padding-top: 10px;
  padding-bottom: 0;
}
.content {
  padding: 0px 45px;
  background-color: #fff;
  .top {
    width: 100%;
    position: relative;
    .add-icon {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .search {
    padding: 0 0 50px 0;
    width: 97%;
    position: relative;
    top: 24px;
    }
    .search-icon {
      position: relative;
      width: 100%;
      right: 6px;
      top: 24px;
    }
    .reset-icon {
      position:relative;
      width: 100%;
      left: 6px;
      top: 24px;
    }
  .feed {
    // background-color: #fafafa;
    border-top: 1px solid #e5e5e5;
    padding: 55px 60px;
    overflow: hidden;
    margin-top: 10px;
    p {
      margin: 0;
    }
    .feed-btn {
      margin-top: 10px;
      float: right;
    }
  }

  .fankui {
    width: 40%;
    margin: 0 auto;
    font-size: 14px;
    p {
      text-align: right;
    }

    a {
      color: #409eff;
      cursor: pointer;
      border-bottom: 1px solid #409eff;
    }
  }
}
</style>
