<template>
  <div class="root">
    <el-form ref="ruleForm" :model="ruleFormModel" :rules="rules" class="demo-ruleForm">
      <el-row>
        <el-col>
          <el-form-item label="标题" prop="title">
            <el-input v-model="ruleFormModel.title"></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="10">
          <el-form-item label="主菜单" prop="region">
            <el-select
              v-model="ruleFormModel.region"
              placeholder="选择主菜单"
              :popper-append-to-body="false"
              style="z-index: 99999"
              @change="changeMenu"
              :disabled="isModifyStatus"
            >
              <el-option
                v-for="(item, index) in menuArr"
                :key="index"
                :label="item.menuName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>-->

        <!-- <el-col :span="10">
          <el-form-item label="子菜单" prop="region">
            <el-select
              v-model="ruleFormModel.submenuVal"
              placeholder="选择子菜单"
              :popper-append-to-body="false"
              style="z-index: 99999"
              :disabled="isModifyStatus"
            >
              <el-option
                v-for="(item, index) in ruleFormModel.submenu"
                :key="index"
                :label="item.menuName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>-->

        <el-col>
          <el-form-item label="搜索关键字" prop="keyWord">
            <!-- <el-input v-model="ruleFormModel.keyWord"></el-input> -->
            <el-tag
              v-for="tag in ruleFormModel.keyWord"
              :key="tag"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >{{ tag }}</el-tag>
            <el-input
              style="width:200px"
              v-if="inputVisible"
              ref="saveTagInput"
              v-model="inputValue"
              class="input-new-tag"
              @keyup.enter="handleInputConfirm"
              @blur="handleInputConfirm"
            ></el-input>
            <el-button v-else class="button-new-tag" :icon="Plus" size="small" @click="showInput"></el-button>
          </el-form-item>
        </el-col>
      </el-row>
      <div ref="editor" class="toolbar"></div>

      <!-- 新的菜单分类选择 -->
      <el-form-item label="问题分类:">
        <!-- <el-button type="primary" @click="selectMenu">问题分类</el-button> -->
        <span
          v-if="menuNameHtml"
          @click="selectMenu"
          style="color:#409eff;font-size:16px;border-bottom:1px solid #409eff;cursor: pointer;"
        >{{ xxx }}</span>
        <a
          v-if="!menuNameHtml"
          @click="selectMenu"
          style="color:#409eff;border-bottom:1px solid #409eff;cursor: pointer;font-size:16px"
        >请选择问题分类</a>
      </el-form-item>
      <el-form-item style="float:right">
        <el-button  type="info" @click="cancelForm">取消</el-button>
        <el-button  type="primary" @click="submitForm">提交</el-button>
      </el-form-item>
      <el-form-item>
        
      </el-form-item>
    </el-form>

    <el-dialog v-model="dialogVisible" title="选择菜单" width="30%">
      <el-tree
        class="permission-tree"
        style="margin-top: 10px"
        :data="menuArr"
        show-checkbox
        node-key="id"
        :highlight-current="true"
        :props="defaultProps"
        :expand-on-click-node="false"
        default-expand-all
        @check="handleCheck"
        :check-strictly="true"
        :default-checked-keys="defaultCheck"
      ></el-tree>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="sureMenu">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  computed,
  toRefs,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import WangEditor from "wangeditor";
import { saveContent, uploadPictures, querySubmenu } from "../service/request";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons";
export default defineComponent({
  name: "Editor",
  setup() {
    const router = useRouter();
    const store = useStore();
    const editor = ref();
    const ruleForm = ref(null);
    const saveTagInput = ref(null)
    const dialogVisible = ref(false)
    const checked = ref(null)
    const defaultCheck = ref([])
    const inputVisible = ref(false)
    const inputValue = ref('')
    const content = reactive({
      html: "", // 存储输入的内容
      instance: null, // 存储 富文本编辑器对象的实例
    });
    //表单信息
    const ruleFormModel = reactive({
      title: "",
      region: 1,
      icon: "",
      keyWord: [],
      submenu: null,
      submenuVal: "",
    });

    const defaultProps = {
      children: "children",
      label: "menuName",
      disabled: "disabled"
    };

    const menuId = computed(() => {
      return store.state.menuId;
    });

    const modifyId = computed(() => {
      return store.state.modifyId;
    });

    const menuFatherId = computed(() => {
      return store.state.menuFatherId;
    });

    // const menuFatherId = ref('')
    const menuName = ref('')
    const menuNameHtml = ref('')

    const modifyContent = reactive({
      contentData: computed(() => {
        return store.state.modifyContent;
      }),
    });

    //表单验证
    const rules = {
      title: [
        {
          required: true,
          message: "请输入标题",
          trigger: "blur",
        },
      ],
      region: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "change",
        },
      ],
      keyWord: [
        {
          required: true,
          message: "请添加关键词",
          trigger: "blur",
        },
      ],
    };

    // const changeMenu = (val) => {
    //   ruleFormModel.region = val;
    //   switchSubMenu(ruleFormModel.region);
    // };

    // const changeSubMenu = (val) => {
    //   ruleFormModel.submenuVal = val;
    // };
    //切换菜单显示
    // const switchSubMenu = (val) => {
    //   let data = {
    //     fatherId: val,
    //   };
    //   querySubmenu(data).then((res) => {
    //     ruleFormModel.submenu = res.data.data;
    //     ruleFormModel.submenuVal = ruleFormModel.submenu[0].id;
    //   });
    // };

    //新增获取子菜单 默认为1-1
    // const getSubMenu = (val) => {
    //   let data = {
    //     fatherId: val,
    //   };
    //   querySubmenu(data).then((res) => {
    //     ruleFormModel.submenu = res.data.data;
    //     ruleFormModel.submenuVal = ruleFormModel.submenu[0].id;
    //   });
    // };

    //修改时菜单
    // const modifyMenu = (val) => {
    //   let data = {
    //     fatherId: val,
    //   };
    //   querySubmenu(data).then((res) => {
    //     ruleFormModel.submenu = res.data.data;
    //   });
    // };

    //提交
    const cancelForm = () => {
      store.commit("setIsShowEditor", false);
    };
    const submitForm = () => {
      ruleForm.value.validate((valid) => {
        if (checked.value !== 1) {
          ElMessage({
            message: "请选择问题分类",
            type: "error",
          });
          return false
        }
        if (valid) {
          content.html = content.instance.txt.html();
          // console.log(content.html);
          let params = {
            title: ruleFormModel.title,
            content: content.html,
            id: modifyId.value,
            menuFatherId: menuFatherId.value,
            menuId: ruleFormModel.submenuVal,
            keyword: ruleFormModel.keyWord,
          };
          saveContent(params)
            .then((res) => {
              ElMessage({
                message: "提交成功",
                type: "success",
              });
              ruleForm.value.resetFields();
              content.instance.txt.html(""); //清空富文本编辑
            })
            .catch((err) => {
              ElMessage({
                message: "提交失败",
                type: "error",
              });
            });
          checked.value = ''
          defaultCheck.value = []
          menuName.value = ''
          menuNameHtml.value = ''
        } else {
          ElMessage({
            message: "表单验证失败",
            type: "error",
          });
        }
      });
    };

    const selectMenu = () => {
      dialogVisible.value = true
    }

    const sureMenu = () => {
      menuNameHtml.value = menuName.value
      if (checked.value == 1) {
        dialogVisible.value = false
        store.commit('setMenuName', menuName.value)
      } else {
        ElMessage({
          message: "必须只能选择一个菜单",
          type: "error",
          appendTo: ".el-overlay"
        });
      }
    }

    const handleCheck = (nodes, resolve) => {
      checked.value = resolve.checkedKeys.length //判断是否多选
      if (resolve.checkedKeys.length == 1) {
        console.log(nodes, resolve);
        menuFatherId.value = resolve.checkedNodes[0].fatherId
        ruleFormModel.submenuVal = resolve.checkedNodes[0].id
        menuName.value = resolve.checkedNodes[0].menuName

        // console.log(menuFatherId.value, ruleFormModel.submenuVal);
      }
    }

    const handleClose = (tag) => {
      ruleFormModel.keyWord.splice(ruleFormModel.keyWord.indexOf(tag), 1)
    }

    const showInput = () => {
      inputVisible.value = true
      nextTick(() => {
        saveTagInput.value.focus()
      })
    }
    const handleInputConfirm = () => {
      if (inputValue.value) {
        ruleFormModel.keyWord.push(inputValue.value)
      }
      inputVisible.value = false
      inputValue.value = ''
    }

    onMounted(() => {
      content.instance = new WangEditor(editor.value);
      //自定义配置
      content.instance.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "foreColor",
        "backColor",
        "justify",
        "image",
      ];
      content.instance.config.showLinkImg = false;

      //上传图片到服务器再拉取
      content.instance.config.customUploadImg = (files, insert) => {
        const formData = new FormData();
        formData.append("fileDatas", files[0]);
        uploadPictures(formData)
          .then((res) => {
           
            ElMessage({
              message: "上传成功",
              type: "success",
            });
            insert(res.data.data); // insert 是获取图片 url 后，插入到编辑器的方法
          })
          .catch((err) => {
            ElMessage({
              message: "上传失败",
              type: "error",
            });
          });
      };
      content.instance.create();

      if (modifyContent.contentData) {
        //修改
        ruleFormModel.title = modifyContent.contentData.title;
        ruleFormModel.keyWord = modifyContent.contentData.keyword;
        content.instance.txt.html(`${modifyContent.contentData.content}`); //富文本显示内容
        defaultCheck.value = [menuId.value]
        checked.value = 1
        menuFatherId.value = computed(() => {
          return store.state.menuFatherId;
        });
        ruleFormModel.submenuVal = menuId.value;
        menuNameHtml.value = computed(() => {
          return store.state.menuName;
        });
      } else {
        //新增
        ruleFormModel.region = 1;
        defaultCheck.value = []
        // getSubMenu(ruleFormModel.region);
      }
    });

    onBeforeUnmount(() => {
      content.instance.destroy();
      content.instance = null;
    });

    return {
      handleInputConfirm,
      handleClose,
      showInput,
      saveTagInput,
      editor,
      content,
      ruleFormModel,
      rules,
      submitForm,
      cancelForm,
      ruleForm,
      dialogVisible,
      // changeMenu,
      // changeSubMenu,
      menuId,
      menuFatherId,
      menuName,
      menuNameHtml,
      modifyId,
      selectMenu,
      sureMenu,
      ...toRefs(modifyContent),
      menuArr: computed(() => store.state.menuArr),
      isModifyStatus: computed(() => store.state.isModifyStatus),
      defaultProps,
      handleCheck,
      checked,
      defaultCheck,
      inputVisible,
      inputValue,
      Plus,
      xxx: computed(() => store.state.menuName)
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.toolbar) {
  // height: 500px !important; /*!important是重点，因为原div是行内样式设置的高度300px*/
  // width: 90% !important;
  margin-bottom: 10px;
  // margin-top: 30px;
}

:deep(.el-overlay) {
  z-index: 99999 !important;
}
.el-form {
  padding: 20px;
}

.root {
  width: 100%;
  height: 100%;
  background-color: #fff;
  // padding: ;
  .w-e-text-container {
    z-index: 99;
  }
  .el-select {
    width: 100%;
  }
  .el-row {
    // width: 90%;
    margin: 0 auto;
    .el-col {
      margin-right: 20px !important;
    }
    // .el-button {
    //   // float: right !important;
    // }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

</style>
